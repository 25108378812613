import { useEffect } from "react"

export const PageLoader = () => {

  useEffect(() => {
    window.scrollTo(0, 0);

    document.body.style.overflow = "hidden";
    return () => document.body.style.overflow = "auto";
  },[])

  return (
    <div className="page-loader">
      {/* <div id="inTurnFadingTextG">
        <div id="inTurnFadingTextG_1" className="inTurnFadingTextG">P</div>
        <div id="inTurnFadingTextG_2" className="inTurnFadingTextG">R</div>
        <div id="inTurnFadingTextG_3" className="inTurnFadingTextG">O</div>
        <div id="inTurnFadingTextG_4" className="inTurnFadingTextG color-red">M</div>
        <div id="inTurnFadingTextG_5" className="inTurnFadingTextG color-red">e</div>
        <div id="inTurnFadingTextG_6" className="inTurnFadingTextG color-red">b</div>
        <div id="inTurnFadingTextG_7" className="inTurnFadingTextG color-red">e</div>
        <div id="inTurnFadingTextG_8" className="inTurnFadingTextG color-red">l</div>
      </div> */}
    </div>
  )
}