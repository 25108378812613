export const navbarLang = {
  ru: {
    main: "Главная",
    projects: "Наши проекты",
    advantages: "Наши преимущества",
    about: "О нас",
    contact: "Контакты",
    mebel: "Мебельная",
    fabrika: "фабрика"
  },
  en: {
    main: "Home",
    projects: "Our projects",
    advantages: "Our advantages",
    about: "About Us",
    contact: "Contacts",
    mebel: "Furniture",
    fabrika: "factory"
  },
  uz: {
    main: "Asosiy",
    projects: "Bizning loyihalarimiz",
    advantages: "Bizning afzalliklarimiz",
    about: "Biz haqimizda",
    contact: "Kontaktlar",
    mebel: "Mebel",
    fabrika: "zavod"
  },
}