export const contactLang = {
  ru: {
    contact: "Контакты",
    name: "Ваше имя",
    tel: "Телефон",
    desc: "Описание",
    send: "Отправить",
  },
  en: {
    contact: "Contacts",
    name: "Your name",
    tel: "Telephone",
    desc: "Description",
    send: "Send",
  },
  uz: {
    contact: "Kontaktlar",
    name: "Sizning ismingiz",
    tel: "Telefon",
    desc: "Tavsif",
    send: "Yuborish",
  },
}