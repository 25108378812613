export const aboutLang = {
  ru: {
    about: "О НАС",
    desc: "Наша компания «PROMEBEL» создана в 2010 году. С небольшого цеха выросла до мебельной фабрики с современным европейским оборудованием, которое дает безупречное качество даже в самых мелких деталях. Производственная площадь нашей фабрики составляет 3500 кв.м., численность производства насчитывает более 70 человек. Мебельная фабрика «PROMEBEL» - является производителем корпусной и металлической мебели, а так же имеет возможность предложить своим клиентам решение по нестандартным проектам. Наша компания совершила настоящий прорыв на мебельном рынке Узбекистана и представила множество позиций, с оответствующих представлению об эстетике и функциональности мебели в интерьере. Фабрика «PROMEBEL» заботится о своих клиентах, ценит их предпочтения и готова помочь каждому в поиске решений для создания первоклассных интерьеров. Продукция «PROMEBEL» ориентирована на мебель среднего сегмента и класса люкс. Наша фабрика использует непреложные правила: качество, сроки, ценовая политика, идея и, безусловно, индивидуальный подход!",
  },
  en: {
    about: "ABOUT US",
    desc: "Our company «PROMEBEL» was established in 2010. From a small shop rose to a furniture factory with modern European equipment, which gives impeccable quality even in the smallest details. The production area of our factory is 3500 sq.m., the size of production has more than 70 people. Furniture factory «PROMEBEL» - is a manufacturer of cabinet and metal furniture, and also has the opportunity to offer its customers a decision on non-standard projects. Our company made a real breakthrough at the furniture market of Uzbekistan and presented many positions with concerning the idea of the aesthetics and functionality of furniture in the interior. The Promebel factory takes care of its customers, appreciates their preferences and is ready to help everyone in finding solutions for creating first-class interiors. «PROMEBEL» products are focused on furniture of the middle segment and luxury. Our factory uses the immutable rules: quality, deadlines, pricing policy, idea and, of course, an individual approach!",
  },
  uz: {
    about: "BIZ HAQIMIZDA",
    desc: "Bizning «PROMEBEL» kompaniyamiz 2010 yilda tashkil etilgan. Kichik do'kondan zamonaviy Evropa uskunalari bilan mebel fabrikasiga ko'tarildi, bu hatto eng kichik tafsilotlarda ham benuqson sifatga ega. Bizning fabrikamizning ishlab chiqarish maydoni 3500 kvadrat metr, ishlab chiqarish hajmi 70 dan ortiq kishiga ega. «PROMEBEL» mebel fabrikasi - shkaf va metall mebellar ishlab chiqaruvchi korxona hisoblanadi, shuningdek, o'z mijozlariga noan'anaviy loyihalar to'g'risida qaror qabul qilish imkoniyatiga ega. Bizning kompaniyamiz O'zbekistonning mebel bozorida haqiqiy yutuqlarga erishdi va intererning estetikasi va funktsional imkoniyatlari g'oyasi haqida ko'plab pozitsiyalarni taqdim etdi. Prombel fabrikasi o'z mijozlariga g'amxo'rlik qiladi, ularning xohish-istaklarini qadrlaydi va barchani birinchi darajali interyer yaratishda yechim topishda yordam berishga tayyor. Prombel mahsulotlari o'rta segment va hashamat mebeliga yo'naltirilgan. Bizning fabrikamiz o'zgarmas qoidalardan foydalanadi: sifat, muddatlar, narx siyosati, g'oya va, albatta, individual yondashuv!",
  },
}