import { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import Header from './components/Header/Header';
import { Footer } from './components/Footer/Footer';
import { AsyncAboutPage, AsyncCategoryPage, AsyncCollectionPage, AsyncContactPage, AsyncHomePage, AsyncProfileProject, AsyncProjectPage } from './components/AsyncPages';
import { PageLoader } from './components/PageLoader';
import Axios from 'axios';

import "bootstrap/dist/css/bootstrap.min.css";
import 'react-image-lightbox/style.css'
import 'react-lazy-load-image-component/src/effects/blur.css';
import "./styles/styles.css";
import Login from './pages/Login';

const App = () => {

  const [background, setBackground] = useState({});
  const [src, setSrc] = useState(null);
  const [noBlur, setNoBlur] = useState(false);

  useEffect(() => {
    if(background.src){
      const img = new Image();
      img.src = background.src;
      img.onload = () => {
        setSrc(background.src);
        setNoBlur(true);
      };
    }
  },[background]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await Axios(process.env.PUBLIC_URL+'/data/background.json');
      setBackground(result.data);
      setSrc(result.data.thumbnail)
    };
    fetchData();

  },[]);

  // useEffect(() => {
  //   if(window.location.hostname.indexOf("admin") > -1 || window.location.hostname.indexOf("localhost") > -1) {
  //     if(!localStorage.getItem("isAuth")){
  //       // history.push('/login')
  //     }
  //   }
  // // eslint-disable-next-line
  // },[location])


  if(!src){
    return <PageLoader />
  }

  return (
    <div className="App"
      style={{
        'backgroundImage': `url(${src})`,
        'filter': noBlur ? "blur(0)" : "blur(2px)",
        'WebkitFilter': noBlur ? "blur(0)" : "blur(2px)"
      }}
    >
      <Header />
      <div className="mebel-app">
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/project/:id" component={AsyncProjectPage} />
          <Route path="/about" component={AsyncAboutPage} />
          <Route path="/category/:id" component={AsyncCategoryPage} />
          <Route path="/profile" component={AsyncProfileProject} />
          <Route path="/collection/:mainId" component={AsyncCollectionPage} />
          <Route path="/contact" component={AsyncContactPage} />
          <Route path="/" component={AsyncHomePage} />
        </Switch>
      </div>
      <Footer />
    </div>
  );
}

export default App;
