import { Link } from "react-router-dom";
import { Logo } from "../../images/temp/Logo";
import fb from '../../images/facebook.png';
import insta from '../../images/instagram.png';

import { navbarLang, footerLang } from "../../language";

export const Footer = () => {

  const lang = localStorage.getItem("lang") || "ru";

  return (
    <div className="mebel-footer">
      <div className="footer-inner">
        <div className="row">
          <div className="col-12 col-sm-2">
            <div className="footer-logo">
              <Logo />
            </div>
          </div>
          <div className="col-12 col-sm-8">

            <div className="footer-nav-menu">
              <div className="footer-nav-menu-item">
                <Link to="/">
                  {navbarLang[lang]?.main}
                </Link>
              </div>
              <span className="nav-divider">/</span>
              <div className="footer-nav-menu-item">
                <Link to="/profile">
                  {navbarLang[lang]?.projects}
                </Link>
              </div>
              <span className="nav-divider">/</span>
              <div className="footer-nav-menu-item">
                <Link to="/">
                  {navbarLang[lang]?.advantages}
                </Link>
              </div>
              <span className="nav-divider">/</span>
              <div className="footer-nav-menu-item">
                <Link to="/about">
                  {navbarLang[lang]?.about}
                </Link>
              </div>
              <span className="nav-divider">/</span>
              <div className="footer-nav-menu-item">
                <Link to="/">
                  {navbarLang[lang]?.contact}
                </Link>
              </div>
            </div>
            <div className="footer-info">
              <p>
                {footerLang[lang]?.address}
                <br />
                {footerLang[lang]?.time}
                <br />
                {footerLang[lang]?.tel}
                <br />
                {footerLang[lang]?.mail}
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-2">
            <div className="footer-social">
              <a href="https://www.instagram.com/promebeluz/" className="mr-2">
                <img alt="instagram" src={insta} className="insta-img" />
              </a>
              <a href="https://www.facebook.com/ProMebel-100861015347139">
                <img alt="facebook" src={fb} className="fb-img" />
              </a>
            </div>
          </div>
        </div>

        <div className="text-center">
          <p className="footer-bottom">
          {new Date().getFullYear()}
          {' '}
          ProMebel.
          {' '} 
          {footerLang[lang]?.licensed}
          </p>
        </div>
      </div>
    </div>
  )
}