import { useEffect, useState } from "react"
import { Motion, spring } from "react-motion"
import { useLocation } from "react-router-dom";

export const Wrapper = ({children}) => {

  const [start, setStart] = useState(false);
  const {pathname} = useLocation();

  useEffect(() => {
    let timer = null;
    if(!start){
      timer = setTimeout(() => {
        setStart(true);
      }, 1000);
    }
    return()=>{
      clearTimeout(timer);
    }
  },[start]);

  useEffect(() => {
    setStart(false);
  },[pathname])

  return (
    <Motion
      style={
        start ? {
          opacity: spring(1),
          translateY: spring(0)
        } : {
          opacity: 0,
          translateY: 200
        } 
      }
    >
      {(style) => (
        <div className="mebel-container">
          {children}
        </div>
      )}
    </Motion>
  )
}