import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import HamburgerMenu from 'react-hamburger-menu';
import { Motion, spring } from 'react-motion';
import { useLocation } from 'react-router-dom';
import Sidebar from 'react-sidebar';
import { Logo } from '../../images/temp/Logo';
import Navbar from '../Navbar';
import SidebarBody from '../SidebarBody';

import { navbarLang } from '../../language';

import './index.css';

const Header = () => {

  const [open, setOpen] = useState (false);
  const [categories, setCategories] = useState([]);

  const [start, setStart] = useState(false);
  const [headerStart, setHeaderStart] = useState(false);
  const [textStart, setTextStart] = useState(false);

  const lang = localStorage.getItem("lang") || "ru";
  
  const {pathname} = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const result = await Axios(process.env.PUBLIC_URL+'/data/categories.json');
      setCategories(result.data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    setStart(false)
    setHeaderStart(false);
    setTextStart(false);  
  }, [pathname])

  useEffect(() => {
    let headerTimer = null
    if(!headerStart){
      headerTimer = setTimeout(() => {
        setHeaderStart(true);
      }, 1200);
      return () => {
        clearTimeout(headerTimer)
      }
    }
  }, [headerStart]);

  useEffect(() => {
    let timer = null
    if(!start){
      timer = setTimeout(() => {
        setStart(true);
      }, 1500);
      return () => {
        clearTimeout(timer)
      }
    }
  }, [start]);
  
  useEffect(() => {
    let textTimer = null
    if(!textStart){
      textTimer = setTimeout(() => {
        setTextStart(true);
      }, 2000);
      return () => {
        clearTimeout(textTimer)
      }
    }
  }, [textStart]);

  return (
    <Motion
      style={
        start ? {
          maxHeight: spring(570, {stiffness: 100, damping: 40})
        } : {
          maxHeight: 1800
        }
      }
    >
      {(style) => (
        <div className="header"
          style={{
            maxHeight: style.maxHeight
          }}
        >
          <div className="overlay" id="main-background">
          </div>
          <Sidebar
            sidebar={<SidebarBody categories={categories} closeIcon={() => setOpen (false)} />}
            open={open}
            onSetOpen={() => setOpen (false)}
            styles={{sidebar: {background: '#ec0007'}}}
          >
            <div className="hamburger-menu-div">
              <HamburgerMenu
                isOpen={open}
                menuClicked={() => {
                  setOpen (!open)
                }}
                width={28}
                height={20}
                strokeWidth={5}
                borderRadius={10}
                color="white"
                animationDuration={0.2}
              />
            </div>
          </Sidebar>

          <Navbar />

          <div className="header-center">
            <Motion
              style={
                headerStart ? 
                  {
                    opacity: spring(1),
                    translateY: spring(0, {stiffness: 80, damping: 25})
                  } : {
                    opacity: 0,
                    translateY: 500
                  }
              }     
            >
              {(header) => (
                <div className="header-logo"
                  style={{
                    transform: `translateY(${header.translateY}px)`,
                    opacity: header.opacity
                  }}
                >
                  <Logo />
                </div>
              )}
            </Motion>
            {pathname === '/' &&
              <Motion 
                style={
                  textStart ? {
                    translateY: spring(0, {stiffness: 80, damping: 25})
                  } : {
                    translateY: 150
                  }
                }
              >
                {(text) => (
                  <div className="header-text">
                    <span
                      style={{
                        display: "block",
                        transform: `translateY(${text.translateY}px)`
                      }}
                    >
                      {navbarLang[lang]?.mebel} <br/> {navbarLang[lang]?.fabrika}
                    </span>
                  </div>
                )}
              </Motion>
            }

          </div>
        </div>
      )}
    </Motion>
  )
}

export default Header;