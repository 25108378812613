import React, { useEffect, useState } from 'react';
import { Motion, presets, spring } from 'react-motion';
import { Link, useLocation } from 'react-router-dom';
import { PhoneIcon } from '../images/temp/PhoneIcon';
import { navbarLang } from '../language';

const Navbar = () => {

  const [menuStart, setMenuStart] = useState(false);
  const [phoneStart, setPhoneStart] = useState(false);

  const lang = localStorage.getItem("lang") || "ru";

  const {pathname} = useLocation()

  useEffect(() => {
    setPhoneStart(false);
    setMenuStart(false);
  }, [pathname]);

  useEffect(() => {
    let menuTimer = null;
    if(!menuStart){
      menuTimer = setTimeout(() => {
        setMenuStart(true);
      }, 200);
    }
    return () => {
      clearTimeout(menuTimer);
    }
  }, [menuStart]);
  
  useEffect(() => {
    let phoneTimer = null;
    if(!phoneStart){
      phoneTimer = setTimeout(() => {
        setPhoneStart(true);
      }, 2000);
    }
    return () => {
      clearTimeout(phoneTimer);
    }
  }, [phoneStart]);
  
  return (
    <div className="navbar">
      <div className="navbar-div w-100">
        <Motion
          style={
            menuStart ? {
              opacity: spring(1),
              translateX: spring(0, {stiffness: 40, damping: 20})
            } : {
              opacity: 0,
              translateX: 1000
            }
          }
        >
          {({opacity, translateX}) => (
            <div className="nav-menu"
              style={{
                opacity: opacity,
                transform: `translateX(${translateX}px)`
              }}
            >
              <div className="nav-menu-item">
                <Link to="/">
                  {navbarLang[lang]?.main} 
                </Link>
              </div>
              <div className="nav-menu-item">
                <Link to="/profile">
                  {navbarLang[lang]?.projects}
                </Link>
              </div>

              <div className="nav-menu-item">
                <Link to="/">
                  {navbarLang[lang]?.advantages}
                </Link>
              </div>

              <div className="nav-menu-item">
                <Link to="/about">
                  {navbarLang[lang]?.about}
                </Link>
              </div>

              <div className="nav-menu-item">
                <Link to="/contact">
                  {navbarLang[lang]?.contact}
                </Link>
              </div>

            </div>
          )}
        </Motion>

        <Motion
          style={
            phoneStart ? {
              opacity: spring(1)
            } : {
              opacity: 0
            }
          }
        >
          {({opacity}) => (
            <div className="nav-tel"
              style={{
                opacity: opacity
              }}
            >
              <div className="nav-tel-inner">
                <PhoneIcon />
                <span>+99899 454-11-11</span>
              </div>
            </div>
          )}
        </Motion>

        <Motion
          style={
            phoneStart ? {
              opacity: spring(1)
            } : {
              opacity: 0
            }
          }
        >
          {({opacity}) => (
            <div className="nav-lang"
              style={{
                opacity: opacity
              }}
            >
              {lang !== "ru" &&
                <div className="nav-lang-inner"
                  onClick={() => {
                    localStorage.setItem("lang", "ru");
                    window.location.reload();
                  }}
                >
                  <span>Ру</span>
                </div>
              }
              {lang !== "uz" &&
                <div className="nav-lang-inner"
                  onClick={() => {
                    localStorage.setItem("lang", "uz");
                    window.location.reload();
                  }}
                >
                  <span>Uz</span>
                </div>
              }
              {lang !== "en" &&
                <div className="nav-lang-inner"
                  onClick={() => {
                    localStorage.setItem("lang", "en");
                    window.location.reload();
                  }}
                >
                  <span>En</span>
                </div>
              }
            </div>
          )}
        </Motion>

      </div>
    </div>
  )
}

export default Navbar;