import { Fragment, useState } from 'react';
import {Link} from 'react-router-dom';

import { appLang } from "../language";

const SidebarBody = ({closeIcon, categories}) => {
  const date = new Date ();
  const [isOpen, setIsOpen] = useState(false);
  const lang = localStorage.getItem("lang") || "ru";
  
  const handleClose = () => {
    setIsOpen(false);
    closeIcon();
  }
  return (
    <div className="sidebar-body-div">
      {/* <div onClick={handleClose} className="close-icon-sidebar">
        <Close  />
      </div> */}
      <div className="sidebar-list">
        <h3>{appLang[lang]?.categories}</h3>
        <ul>
          {categories.map(item => {
            if(item.inMenu){
              return null
            }
            return (
              <li key={item.id} className={item.isMenu ? "mb-2 menu-li":"mb-2" }>
                {item.isMenu ?
                  <Fragment>
                    <span className="sidebar-menu-item" onClick={()=>setIsOpen(!isOpen)}>
                      <span className={isOpen ? "sidebar-menu-item-plus active": "sidebar-menu-item-plus"}>
                        +
                      </span>
                      <span> {item.title[lang]} </span>
                    </span>
                    <ul className={isOpen ? "open-menu" : "hide-menu"}>
                      {item.subMenus.map(subItem => (
                        <li key={subItem.id} className="mb-2" onClick={handleClose}>
                          <Link to={"/category/"+subItem.id} >{subItem.title[lang]}</Link>
                        </li>      
                      ))}
                    </ul> 
                  </Fragment>
                  :
                  <Link to={"/category/"+item.id} onClick={handleClose} >{item.title[lang]}</Link>
                }
              </li>
            )
          })}
        </ul>
      </div>
      <div className="sidebar-footer">
        <p className="sidebar-copy">
          &#169; {date.getFullYear ()} ProMebel
        </p>
      </div>
    </div>
  );
};

export default SidebarBody;
