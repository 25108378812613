export const Logo = () => (
 <svg xmlns="http://www.w3.org/2000/svg" 
  xmlSpace="preserve" width="100px" height="100px" version="1.1" 
  style={{shapeRendering: 'geometricPrecision', textRendering: 'geometricPrecision', imageRendering: 'optimizeQuality', fillRule: 'evenodd', clipRule: 'evenodd'}} 
  viewBox="0 0 1754 691.81" 
  xmlnsXlink="http://www.w3.org/1999/xlink" >
  <defs>
    <style type="text/css" dangerouslySetInnerHTML={{__html: "\n   \n    .fil1 {fill:#FEFEFE}\n    .fil0 {fill:#E31E24}\n   \n  " }} />
  </defs>
  <g id="Слой1">
    <metadata id="CorelCorpID_0Corel-Layer" />
    <g id="_1765036413296">
      <path className="fil0" d="M565.86 58.54l0 143.17 69.13 0c38.57,-7.51 64.26,-44.22 58.1,-83.02 -6.16,-38.81 -19.1,-64.96 -58.1,-60.15l-69.13 0z" />
      <path className="fil1" d="M798.87 0l0 439.96 -233.01 0 0 -173.47 86.32 0c68.39,-10.32 117.83,-70.79 114.35,-139.87 -3.48,-69.08 -45.27,-122.93 -114.35,-126.34l146.69 0 0 -0.28z" />
      <polygon className="fil1" points="997.82,439.96 860.25,70.63 860.25,439.96 " />
      <polygon className="fil1" points="1191.07,439.96 1191.07,70.63 1053.49,439.96 " />
      <polygon className="fil0" points="1149.06,0.28 1025.66,330.63 902.25,0.28 1025.66,0.28 " />
      <path className="fil1" d="M199.98 524.99c-0.01,-9.41 -3.75,-18.42 -10.4,-25.06 -6.65,-6.65 -15.62,-10.38 -25.03,-10.37l-164.55 0 0 166.66c0.01,9.4 3.75,18.42 10.4,25.06 6.65,6.64 15.62,10.37 25.02,10.37l3.98 0 0 -162 121.18 0 0 40.45 -70.14 0c-9.4,0 -18.42,3.74 -25.06,10.39 -6.64,6.66 -10.37,15.63 -10.37,25.03l0 4.1 109.54 0c9.41,-0.01 18.42,-3.75 25.06,-10.4 6.65,-6.65 10.38,-15.62 10.37,-25.03l0 -49.2z" />
      <path className="fil1" d="M256.52 691.65l4.05 0 0 -162 121.17 0 0 40.45 -110.39 0 92.55 121.55 54.46 0 -61.89 -82.03 29.25 0c9.4,-0.01 18.42,-3.75 25.06,-10.4 6.64,-6.65 10.37,-15.62 10.36,-25.03l0 -49.2c0,-9.41 -3.74,-18.42 -10.39,-25.06 -6.66,-6.65 -15.63,-10.38 -25.03,-10.37l-164.55 0 0 166.66c0,9.4 3.74,18.42 10.39,25.06 6.66,6.64 15.56,10.37 24.96,10.37z" />
      <path className="fil1" d="M606.17 691.65c9.4,-0.01 18.41,-3.75 25.05,-10.4 6.65,-6.65 10.38,-15.63 10.37,-25.03l0 -131.23c0,-9.41 -3.75,-18.42 -10.4,-25.06 -6.65,-6.65 -15.62,-10.38 -25.02,-10.37l-164.56 0 0 4.66c0.01,9.4 3.75,18.42 10.4,25.06 6.65,6.64 15.63,10.37 25.03,10.37l125.82 0 0 122.26 -122.1 0 0 -107.31 -3.72 0c-9.4,0 -18.42,3.74 -25.06,10.39 -6.64,6.65 -10.37,15.63 -10.37,25.03l0 76.2c0.01,9.4 3.75,18.42 10.4,25.06 6.65,6.64 15.63,10.37 25.03,10.37l129.13 0z" />
      <path className="fil0" d="M760.78 649.11l-62.99 -68.42 0 75.69c0.01,9.39 -3.71,18.4 -10.35,25.04 -6.63,6.64 -15.68,10.38 -25.07,10.39l-2.32 0 0 -202.23c6.95,-0.09 13.59,2.9 18.13,8.16l82.61 95.64 82.61 -95.64c4.55,-5.26 11.18,-8.25 18.13,-8.18l0 202.25 -2.32 0c-9.39,0 -18.39,-3.72 -25.04,-10.36 -6.64,-6.63 -10.38,-15.68 -10.38,-25.07l0 -75.69 -63.01 68.42z" />
      <path className="fil0" d="M1146.61 652.09l124.62 0 0 -40.97 -111.88 0 0 -4.29c-0.01,-9.39 3.71,-18.4 10.35,-25.04 6.63,-6.64 15.68,-10.38 25.07,-10.39l76.46 0 0 -41.75 -347.36 0 0 41.75 159.83 0 0 2.98c0.01,9.39 -3.72,18.4 -10.35,25.04 -6.64,6.65 -15.68,10.38 -25.07,10.39l-124.41 0 0 42.28 159.83 0 0 4.29c0.01,9.39 -3.72,18.4 -10.35,25.04 -6.64,6.64 -15.68,10.38 -25.07,10.39l-128.26 0c-9.39,0 -18.4,-3.72 -25.04,-10.36 -6.65,-6.63 -10.38,-15.68 -10.39,-25.07l0 -131.39c0,-9.39 3.72,-18.4 10.36,-25.04 6.63,-6.65 15.68,-10.38 25.07,-10.39l355.65 0c9.39,-0.01 18.4,3.72 25.04,10.35 6.64,6.64 10.38,15.69 10.38,25.08l0 131.39c0.01,9.39 -3.71,18.4 -10.35,25.04 -6.63,6.64 -15.68,10.38 -25.07,10.39l-168.08 0 0 -146.96 39.49 0 -0.47 107.24z" />
      <path className="fil0" d="M1370.3 529.27l0 42.13 161.8 0 0 4.29c0,9.39 -3.72,18.4 -10.35,25.04 -6.64,6.65 -15.69,10.38 -25.08,10.39l-126.37 0 0 40.97 161.8 0 0 4.29c0,9.39 -3.72,18.4 -10.35,25.04 -6.64,6.64 -15.69,10.38 -25.08,10.39l-130.42 0c-9.39,0 -18.39,-3.72 -25.04,-10.36 -6.64,-6.63 -10.38,-15.68 -10.38,-25.07l0 -131.39c-0.01,-9.39 3.72,-18.4 10.35,-25.04 6.64,-6.65 15.68,-10.38 25.07,-10.39l191.19 0c9.39,-0.01 18.4,3.72 25.04,10.35 6.64,6.64 10.38,15.69 10.39,25.08l0 127.1 161.13 0 0 4.29c0.01,9.39 -3.72,18.4 -10.35,25.04 -6.64,6.64 -15.68,10.38 -25.07,10.39l-128.47 0c-9.39,0 -18.4,-3.72 -25.04,-10.36 -6.64,-6.63 -10.38,-15.68 -10.38,-25.07l0 -126.73 -184.39 -0.38z" />
    </g>
  </g>
</svg>
)