export { navbarLang } from "./navbar";
export { footerLang } from "./footer";
export { aboutLang } from "./about";
export { contactLang } from "./contact";


export const appLang = {
  ru: {
    contact: "Контакты",
    project: "Наши Проекты",
    moreProjects: "ЕЩЕ ПРОЕКТЫ",
    categories: "Категории",
    more: "подробней о проекте",
    offers: "НАШИ ПРЕДЛОЖЕНИЯ",
    partners: "НАШИ ПАРТНЕРЫ",
    desc: "Реализация любых дизайнерских идей, любой сборки и сложности. Сбалансированное соответствие эстетики и эргономики в каждом изделии. Работа дизайнеров и заказчиков в тесном сотрудничестве с высокой культурой производства. Использование исключительно ценных материалов и современной фурнитуры. Гарантия на всю продукцию и последующее обслуживание."
  },
  en: {
    contact: "Contacts",
    moreProjects: "MORE PROJECTS",
    project: "Our projects",
    categories: "Categories",
    more: "about the project",
    offers: "OUR OFFERS",
    partners: "OUR PARTNERS",
    desc: "Implementation of any designer ideas, any configuration and complexity. Balanced combination of aesthetics and ergonomics in each product. The work of designers and customer in close cooperation, high culture of production. Use exclusively high-quality materials and modern fittings. Warranty on all products and subsequent maintenance."

  },
  uz: {
    contact: "Kontaktlar",
    moreProjects: "KO'PROQ LOYIHALAR",
    project: "Bizning loyihalarimiz",
    categories: "Toifalar",
    more: "loyiha haqida",
    offers: "BIZNING TAKLIFLARIMIZ",
    partners: "BIZNING HAMKORLARIMIZ",
    desc: "Har qanday dizayner g'oyalarini, har qanday konfiguratsiya va murakkablikni amalga oshirish. Har bir mahsulotda estetik va ergonomikalarning muvozanatli kombinatsiyasi. Dizaynerlar va mijozlarning yaqin hamkorlik, yuqori ishlab chiqarish madaniyatida. Faqat yuqori sifatli materiallar va zamonaviy fittinglardan foydalanish. Barcha mahsulotlar va keyingi xizmatlar uchun kafolat."
  },
}