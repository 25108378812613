export const footerLang = {
  ru: {
    address: "Адрес: Узбекистан, г Ташкент ул Джаркурганская (Хамзинский Компьютерный Колледж)",
    time: "Режим работы  ПН-СБ, с 9.00-18.00 (обед 13.00-14.00)",
    tel: "Тел.: 998 99 454 11 11",
    mail: "Почта ceo.promebel@gmail.com",
    licensed: "Услуги лицензированы. Все права защищены.",
  },
  en: {
    address: "Address: Uzbekistan, Tashkent st. Jarkorgon (Khamza Computer College)",
    time: "Mode of operation Mon-Sat, from 9.00-18.00 (lunch 13.00-14.00)",
    tel: "Tel.: 998 99 454 11 11",
    mail: "Mail ceo.promebel@gmail.com.",
    licensed: "Services are licensed. All rights reserved.",
  },
  uz: {
    address: "Manzil: O'zbekiston, Toshkent Jarqo'rg'on ko'chasi (Hamza kompyuter kolleji)",
    time: "Ishlash tartibi DU-SHA, 9.00-18.00 dan (tushlik 13.00-14.00)",
    tel: "Tel.: 998 99 454 11 11",
    mail: "Pochta ceo.promebel@gmail.com.",
    licensed: "Xizmatlar litsenziyaga ega. Barcha huquqlar himoyalangan.",
  },
}