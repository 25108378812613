import Loadable from 'react-loadable';
import {PageLoader} from './PageLoader';

const AsyncProjectPage = Loadable({
  loader: ()=> import("../pages/ProjectPage"),
  loading: ()=> <PageLoader />,
});
const AsyncProjectContainer = Loadable({
  loader: ()=> import("../containers/ProjectContainer"),
  loading: ()=> <PageLoader />,
});


const AsyncAboutPage = Loadable({
  loader: ()=> import("../pages/AboutPage"),
  loading: ()=> <PageLoader />
});


const AsyncCategoryPage = Loadable({
  loader: ()=> import("../pages/CategoryPage"),
  loading: ()=> <PageLoader />
});


const AsyncProfileProject = Loadable({
  loader: ()=> import("../pages/ProfilePage"),
  loading: ()=> <PageLoader />
});


const AsyncCollectionPage = Loadable({
  loader: ()=> import("../pages/CollectionPage"),
  loading: ()=> <PageLoader />
});


const AsyncHomePage = Loadable({
  loader: ()=> import("../pages/HomePage"),
  loading: ()=> <PageLoader />
});

const AsyncContactPage = Loadable({
  loader: ()=> import("../pages/ContactPage"),
  loading: ()=> <PageLoader />
});

export {
  AsyncAboutPage,
  AsyncCategoryPage,
  AsyncProfileProject,
  AsyncProjectPage,
  AsyncCollectionPage,
  AsyncHomePage,
  AsyncProjectContainer,

  AsyncContactPage
}